var generic = generic || {};
var Drupal = Drupal || {};

(function ($) {
  Drupal.behaviors.productHairQuizMatchV1 = {
    attached: false,
    matches: null,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      if (!self.matches) {
        self.getHairProductResultFetch()
          .always(function () {
            self.applyMatches(context);
            $(document).trigger('hairquiz.perfect_match', [self.sortPerfectMatchFn, self]);
          });
      }
    },
    applyMatches: function (context) {
      var self = this;
      var $productContainer = $('.js-product', context);
      var $productHairQuizSection = $('.js-product-hair-quiz-match', $productContainer);

      if (!self.matches) {
        return;
      }
      $productHairQuizSection.each(function () {
        var $product = $(this).closest('.js-product');
        var $miscFlag = $('.js-misc-flag-text', $product);
        var $miscFlagBrief = $('.js-prd-brief-misc-flag-text', $product);
        var productId = $(this).data('product-id');

        if (self.isMatch(productId)) {
          $product.addClass('is-recommended-product');
          $product.parent().addClass('product-grid__item--filter-recommended');
          $(this).removeClass('hidden');
          $miscFlag.hide();
          $miscFlagBrief.hide();
        }
      });
    },
    isMatch: function (prodId) {
      var hairQuizProductIds = this.matches;

      return hairQuizProductIds && hairQuizProductIds.indexOf(prodId) >= 0;
    },
    getHairProductResultFetch: function () {
      var self = this;
      var deferred = $.Deferred();

      generic.jsonrpc.fetch({
        method: 'quiz.getHairCareFinderResults',
        params: [],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getValue();
          var quizProducts = response.quiz_skus;

          if (quizProducts && quizProducts.length > 0) {
            self.matches = quizProducts.map(function (quiz_sku) {
              return quiz_sku.PRODUCT_ID;
            });
          }
          deferred.resolve();
        },
        onFailure: function (err) {
          deferred.reject(err);
        }
      });

      return deferred.promise();
    },
    sortPerfectMatchFn: function (prodsData, objData) {
      var hairQuizProductIds = objData.matches;
      var prodMatches = [];
      var noProdMatches = [];

      if (!!prodsData && !!hairQuizProductIds) {
        prodsData.forEach(function (prod) {
          if (!!prod && hairQuizProductIds.indexOf(prod.PRODUCT_ID) >= 0) {
            prodMatches.push(prod);
          } else {
            noProdMatches.push(prod);
          }
        });
        prodsData = prodMatches.concat(noProdMatches);
      }

      return prodsData;
    }

  };
})(jQuery);
